import * as React from "react"

import Seo from "../components/seo"

const Terms = () => (
  <>
    <Seo title="Conditions Générales Utilisateur" />
    <div className="mx-auto py-16 px-4 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:py-20 lg:px-8">
      <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div className="max-w-xl mb-10 sm:text-center md:mx-auto md:mb-12 lg:max-w-2xl">
          <div>
            <p className="rounded-full font-semibold py-px bg-teal-accent-400 text-xs mb-4 tracking-wider text-teal-900 inline-block uppercase">
              Maxhel.fr
            </p>
          </div>
          <h2 className="font-sans font-bold max-w-lg mb-6 leading-none tracking-tight text-3xl text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="-mt-8 -ml-20 top-0 left-0 text-blue-gray-100 w-32 z-0 absolute hidden sm:block lg:-mt-10 lg:-ml-28 lg:w-32"
              >
                <defs>
                  <pattern
                    id="70326c9b-4a0f-429b-9c76-792941e326d5"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#70326c9b-4a0f-429b-9c76-792941e326d5)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative">Les</span>
            </span>{' '}
          conditions générales d'utilisation du site.
          </h2>

        </div>
      </div>
      <div className="max-w-screen-xl sm:mx-auto">
        <div className="font-roboto grid gap-16 grid-cols-1 row-gap-8 lg:grid-cols-2">
          <div className="space-y-8">
            <div>
              <p className="font-semibold text-xl mb-4">
                ARTICLE 1. INFORMATIONS LÉGALES
              </p>
              <p className="text-justify text-gray-700">
                En vertu de l'article 6 de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé dans cet article l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
                <br />
                <br />
              Le site MAXHEL est édité par :<br />
              LAURENT URGIN, dont le siège siège social est situé à l'adresse suivante :<br /> 14 AVENUE MARIE CURIE et immatriculée au registre suivant : 84770690000010.<br />
              Téléphone : 0777680354/ Adresse e-mail : laurenturgin@maxhel.fr.
              <br />
                <br />
              Le directeur de publication du site est : LAURENT URGIN.<br />
              Le site MAXHEL est hébergé par :<br /><br />
              Netlify,Inc., dont le siège est situé à l'adresse ci-après :
              2325 3RD STREET, SUITE 215, SAN FRANCISCO, CA 94107 (USA).
            </p>
            </div>
            <div>
              <p className="font-semibold text-xl mb-4">
                ARTICLE 2. PRÉSENTATION DU SITE
              </p>
              <p className="text-justify text-gray-700">
                Le site MAXHEL a pour objet :
              <ul className="list-disc list-inside">
                  <li>Personnalisation de textiles</li>
                  <li>Personnalisation d’accessoires</li>
                  <li>Aide à la création de boutique en ligne ou physique</li>
                </ul>
              </p>
            </div>
            <div>
              <p className="font-semibold text-xl mb-4">
                ARTICLE 3. CONTACT
              </p>
              <p className="text-justify text-gray-700">
                Pour toute question ou demande d'information concernant le site, ou tout signalement de contenu ou d'activités illicites, l'utilisateur peut contacter l'éditeur à l'adresse e-mail suivante: laurenturgin@maxhel.fr ou adresser un courrier recommandé avec accusé de réception à : LAURENT URGIN - 14 AVENUE MARIE CURIE
              </p>
              <br />
              <p className="text-justify text-gray-700">
                Le site et ses différents services peuvent être interrompus ou suspendus par l’Éditeur, notamment à l’occasion d’une maintenance, sans obligation de préavis ou de justification.
               </p>
            </div>


            <div>
              <p className="font-semibold text-xl mb-4">
                ARTICLE 4. ACCEPTATION DES CONDITIONS D'UTILISATION
              </p>
              <p className="text-justify text-gray-700">
                L'accès et l'utilisation du site sont soumis à l'acceptation et au respect des présentes Conditions Générales d'Utilisation.
               <br /><br />
               L'éditeur se réserve le droit de modifier, à tout moment et sans préavis, le site et des services ainsi que les présentes CGU, notamment pour s'adapter aux évolutions du site par la mise à disposition de nouvelles fonctionnalités ou la suppression ou la modification de fonctionnalités existantes.

              <br /><br />
              Il est donc conseillé à l'utilisateur de se référer avant toute navigation à la dernière version des CGU, accessible à tout moment sur le site. En cas de désaccord avec les CGU, aucun usage du site ne saurait être effectué par l'utilisateur.
              </p>
            </div>


            <div>
              <p className="font-semibold text-xl mb-4">
                ARTICLE 5. ACCÈS ET NAVIGATION
              </p>
              <p className="text-justify text-gray-700">
                L'éditeur met en œuvre les solutions techniques à sa disposition pour permettre l'accès au site 24 heures sur 24, 7 jours sur 7. Il pourra néanmoins à tout moment suspendre, limiter ou interrompre l'accès au site ou à certaines pages de celui-ci afin de procéder à des mises à jours, des modifications de son contenu ou tout autre action jugée nécessaire au bon fonctionnement du site.
            <br /><br />
            La connexion et la navigation sur le site MAXHEL valent acceptation sans réserve des présentes Conditions Générales d'Utilisation, quelques soient les moyens techniques d'accès et les terminaux utilisés.
            <br /><br />
            Les présentes CGU s'appliquent, en tant que de besoin, à toute déclinaison ou extension du site sur les réseaux sociaux et/ou communautaires existants ou à venir.
              </p>
            </div>


            <div>
              <p className="font-semibold text-xl mb-4">
                ARTICLE 6. GESTION DU SITE
              </p>
              <p className="text-justify text-gray-700">
                Pour la bonne gestion du site, l'éditeur pourra à tout moment :
              </p>
              <ul className="list-disc list-inside">
                <li>Suspendre, interrompre ou limiter l'accès à tout ou partie du site, réserver l'accès au site, ou à certaines parties du site, à une catégorie déterminée d'internaute; </li>
                <li>Supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales, ou avec les règles de la Nétiquette ; </li>
                <li>Suspendre le site afin de procéder à des mises à jour ;</li>
              </ul>
            </div>



          </div>
          <div className="space-y-8">
            <div>
              <p className="font-semibold text-xl mb-4">
                ARTICLE 7. RESPONSABILITÉS</p>
              <p className="text-justify text-gray-700">
                L'éditeur n'est responsable que du contenu qu'il a lui-même édité. <br />
              L'éditeur n'est pas responsable :
              </p>
              <ul className="list-disc list-inside">
                <li>En cas de problématiques ou défaillances techniques, informatiques ou de compatibilité du site avec un matériel ou logiciel quel qu'il soit ;</li>
                <li>Des dommages directs ou indirects, matériels ou immatériels, prévisibles ou imprévisibles résultant de l'utilisation ou des difficultés d'utilisation du site ou de ses services ; </li>
                <li>Des caractéristiques intrinsèques de l'Internet, notamment celles relatives au manque de fiabilité et au défaut de sécurisation des informations y circulant ;</li>
                <li>Des contenus ou activités illicites utilisant son site et ce, sans qu'il en ait pris dûment connaissance au sens de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique et la Loi n°2004-801 du 6 août 2004 relative à la protection des personnes physiques à l'égard de traitement de données à caractère personnel.</li>
              </ul>
              <br />

              <p className="text-justify text-gray-700">
                Par ailleurs, le site ne saurait garantir l'exactitude, la complétude, et l'actualité des informations qui y sont diffusées.<br />
              L'utilisateur est responsable :
            </p>
              <ul className="list-disc list-inside">
                <li>De la protection de son matériel et de ses données ;</li>
                <li>De l'utilisation qu'il fait du site ou de ses services ;</li>
                <li>S'il ne respecte ni la lettre, ni l'esprit des présentes CGU.</li>

              </ul>
            </div>
            <div>
              <p className="font-semibold text-xl mb-4">
                ARTICLE 8. Liens hypertextes
              </p>
              <p className="text-justify text-gray-700">
                Le site peut contenir des liens hypertextes pointant vers d'autres sites internet sur lesquels MAXHEL n'exerce pas de contrôle. Malgré les vérifications préalables et régulières réalisés par l'éditeur, celui-ci décline tout responsabilité quant aux contenus qu'il est possible de trouver sur ces sites.
                <br /><br />
                L'éditeur autorise la mise en place de liens hypertextes vers toute page ou document de son site sous réserve que la mise en place de ces liens ne soit pas réalisée à des fins commerciales ou publicitaires.
              <br /><br />
              En outre, l'information préalable de l'éditeur du site est nécessaire avant toute mise en place de lien hypertexte.<br /><br />
              Sont exclus de cette autorisation les sites diffusant des informations à caractère illicite, violent, polémique, pornographique, xénophobe ou pouvant porter atteinte à la sensibilité du plus grand nombre.<br /><br />
              Enfin, MAXHEL se réserve le droit de faire supprimer à tout moment un lien hypertexte pointant vers son site, si le site l'estime non conforme à sa politique éditoriale.
            </p>
            </div>

            <div>
              <p className="font-semibold text-xl mb-4">
                ARTICLE 9. CONFIDENTIALITÉ
              </p>
              <p className="text-justify text-gray-700">
                En plus des présentes Conditions Générales, le site dispose d'une politique de confidentialité qui décrit la manière dont les données à caractère personnel sont traitées lorsque l'utilisateur se rend sur le site, ainsi que la manière dont les cookies sont utilisés.<br /><br />
              En naviguant sur le site, l'utilisateur déclare avoir également pris connaissance de la politique de confidentialité susmentionnée.
            </p>
            </div>


            <div>
              <p className="font-semibold text-xl mb-4">
                ARTICLE 10. PROPRIÉTÉ INTELLECTUELLE
              </p>
              <p className="text-justify text-gray-700">
                La structuration du site mais aussi les textes, graphiques, images, photographies, sons, vidéos et applications informatiques qui le composent sont la propriété de l'éditeur et sont protégés comme tels par les lois en vigueur au titre de la propriété intellectuelle.<br /><br />
              Toute représentation, reproduction, adaptation ou exploitation partielle ou totale des contenus, marques déposées et services proposés par le site, par quelque procédé que ce soit, sans l'autorisation préalable, expresse et écrite de l'éditeur, est strictement interdite et serait susceptible de constituer une contrefaçon au sens des articles L. 335-2 et suivants du Code de la propriété intellectuelle. Et ce, à l'exception des éléments expressément désignés comme libres de droits sur le site.<br /><br />
              L'accès au site ne vaut pas reconnaissance d'un droit et, de manière générale, ne confère aucun droit de propriété intellectuelle relatif à un élément du site, lesquels restent la propriété exclusive de l'éditeur.<br />
              Il est interdit à l'utilisateur d'introduire des données sur le site qui modifieraient ou qui seraient susceptibles d'en modifier le contenu ou l'apparence.
              </p>
            </div>

            <div>
              <p className="font-semibold text-xl mb-4">
                ARTICLE 11. LOI APPLICABLE ET JURIDICTION COMPÉTENTE
              </p>
              <p className="text-justify text-gray-700">
                Les présentes Conditions Générales d'Utilisation sont régies par la loi française. En cas de différend et à défaut d'accord amiable, le litige sera porté devant les tribunaux français conformément aux règles de compétence en vigueur.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default Terms